<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("role.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      placeholder="Search"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("role.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listRole.length > 0">
          <thead>
          <tr>
            <th class="text-start">
              {{ $t("role.no") }}
            </th>
            <th class="text-left">
              {{ $t("role.name") }}
            </th>
            <th class="text-left">
              {{ $t("role.display_name") }}
            </th>
            <th class="text-left">
              {{ $t("role.description") }}
            </th>
            <th class="text-left">
              {{ $t("role.created") }}
            </th>
            <th class="text-left">
              {{ $t("role.updated") }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listRole" :key="idx">
            <td>
              {{ idx + 1 }}
            </td>
            <td>
              {{ item.name }}
            </td>

            <td>
              {{ item.display_name }}
            </td>
            <td>
              <div v-if="item.description">
                {{ item.description }}
              </div>
              <div v-else>-</div>
            </td>
            <td>
              {{ item.created_at }}
            </td>
            <td>
              {{ item.updated_at }}
            </td>
            <td class="text-start">


              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Pagination
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchRole"
        >
        </Pagination>

        <ModalEdit fullscreen title="Edit Role">
          <template v-slot="{ close }">
            <EditRole :role="role" @close="close" @success="fetchRole()"/>
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{close}">
            <!--            <DeletePlan :plan_id="plan_id" @close="close" @success="fetchRole()"/>-->
          </template>
        </ModalDelete>
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditRole from "@/views/MainAdmin/Role/CRUD/EditRole";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    EditRole
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listRole: [],
      role_id: "",
      role: {}

    }
  },
  methods: {
    searchFilterItem() {
      this.fetchRole();
    },

    filterRole(role_id) {
      return (
          this.listRole.filter((item) => {
            return item.id == role_id;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({name: 'role.create'}).catch(() => {
      });
    },

    onEdit(role_id) {
      this.role = {
        ...this.filterRole(role_id)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(role_id) {
      this.role_id = role_id
      this.$store.commit("modalDelete_State", true);
    },

    fetchRole() {
      this.isLoading = true;
      this.$axios.get(`admin/list/roles`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listRole = res.data.data.data;
          console.log(this.listRole)
          this.pagination = res.data.data.pagination;
          if (!this.listRole.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
        this.isLoading = false;
      }).catch(error => {
        console.log(error)
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchRole();
  }
}
</script>

<style scoped lang="scss">
</style>
